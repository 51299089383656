import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.documentMangementRoute.root
// const commentRootpath = endPoint.commentTask.root
// const individualTask = endPoint.individualTask.root

export const createNewOne = async (parameters) => {
    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path: endPoint.documentMangementRoute.createNewOne,
            method: endPoint.methodType.post
        })
    )?.data
}


export default {
    createNewOne
}
