import endPoint from "./_endPoint"
import { axiosCallAdvanced } from "./_main"
const rootPath = endPoint.temperatureLogRoute.root


export const temperatureLogList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.temperatureLogRoute.webAppListForWeb
		})
	)?.data
}

export const exportLogList = async (parameters) => {
	return (
		await axiosCallAdvanced({
			...parameters,
			baseURL: rootPath,
			path: endPoint.temperatureLogRoute.exportWebAppListForWeb
		})
	)?.data
}

export const findOne = async (parameters) => {
    const path = endPoint.temperatureLogRoute.findOne.replace(':id', parameters.logID)

    return (
        await axiosCallAdvanced({
            ...parameters,
            baseURL: rootPath,
            path
        })
    )?.data
}


export default {
	temperatureLogList,
	exportLogList,
	findOne
}